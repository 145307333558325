@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-ExtraBold.woff2') format('woff2'),
        url('../fonts/OpenSans-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-BoldItalic.woff2') format('woff2'),
        url('../fonts/OpenSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Bold.woff2') format('woff2'),
        url('../fonts/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Light.woff2') format('woff2'),
        url('../fonts/OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-LightItalic.woff2') format('woff2'),
        url('../fonts/OpenSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Regular.woff2') format('woff2'),
        url('../fonts/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-SemiBold.woff2') format('woff2'),
        url('../fonts/OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/OpenSans-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/OpenSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Italic.woff2') format('woff2'),
        url('../fonts/OpenSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

$generalFonts: "Segoe Ui", 'Open Sans', Tahoma, Geneva, Verdana, sans-serif;

// Animations

@-webkit-keyframes BackgroundAnim {
    0% {
        background-position: 84% 0%;
    }
    50% {
        background-position: 17% 100%;
    }
    100% {
        background-position: 84% 0%;
    }
}

@-moz-keyframes BackgroundAnim {
    0% {
        background-position: 84% 0%;
    }
    50% { 
        background-position: 17% 100%;
    }
    100% { 
        background-position: 84% 0%; 
    }
}
@keyframes BackgroundAnim {
    0% {
        background-position: 84% 0%;
    }
    50% {
        background-position: 17% 100%; 
    }
    100% {
        background-position: 84% 0%;
    }
}

@-moz-keyframes spin { 
    100% { 
        -moz-transform: rotate(360deg); 
    } 
}
@-webkit-keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
    } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform: rotate(360deg); 
    } 
}

@keyframes flickerAnimation {
    0%   { opacity:0.2; }
    50%  { opacity:1; }
    100% { opacity:0.2; }
}
// Styling below

body, html {
    font-family: $generalFonts;
    margin: 0;
    padding: 0;
    color: white;
    width: 100%;
    height: 100%;
    // background: linear-gradient(270deg, #EA2640, #1e0047, #B8675C);
    
}

html {
    background-color: #20002e;   
}

body {
    overflow-y: scroll;
}

#hiddenBox {
    position: absolute;
    left: -300vw;
    top: -400vh;
    font-weight: bold;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
    
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: white;
    
    border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: lightgrey;
    
    
}

#root {
    width: 100%;
    height: 100%;
}

.logo {
    content: "regimensocial";
    font-variant: small-caps;
}

.sCaps {
    font-variant: small-caps;
}

.sepButtons {
    margin-right: 3px;
}

//

svg.dayElement {
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    box-sizing: border-box;
    text {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        text-shadow: rgba(255, 255, 255, 0.1) 0px 0px 1px;
    }
}

// Generic items

input {
    width: 100%;
    min-height: 40px;
    border: 1px solid white;
    padding: 8px;
    border-radius: 20px;
    background: transparent;
    color: white;
    overflow: hidden;
    margin: 0 auto;
    display: block;
    outline: none;
    box-sizing: border-box;
    font-family: $generalFonts;
}


input::placeholder {
    
    font-family: $generalFonts;
    color: white;
    opacity: 0.6;
    font-style: italic;
    font-variant: small-caps;
    text-align: center;
    font-size: 1.3em;
}

input[type='radio'], input[type='checkbox'], input.standard {
    width: unset;
    min-height: unset;
    border: unset;
    padding: unset;
    border-radius: unset;
    background: unset;
    color: unset;
    overflow: unset;
    margin: unset;
    display: unset;
    outline: unset;
    box-sizing: unset;
}


input.regularText {
    border: unset;
    width: unset;
    text-align: center;
    padding: unset;
    font-size: unset;
    display: inline;
}

textarea.regularText {
    border: unset;
    width: unset;
    text-align: center;
    padding: unset;
    font-size: unset;
    display: inline;
    font-family: $generalFonts;
    background: none;
    color: white;
    font-size: 1em;
    width: 100%;
    outline: none;
    resize: none;
}

img.standard {
    max-width: 100%;
    border-radius: 25px;
    border: 2px solid #ffffff35;
}

a {
    color: white;
}

// 


#main {
    overflow: hidden;
    width: 600px;
    max-width: 100%;
    min-height: 100vh;
    border: 2px solid #ffffff15;
    border-bottom: none;
    border-top: none;
    margin: 0 auto;
    
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    // 
    padding: 25px;
    padding-bottom: 100px;
    box-sizing: border-box;
    background-color: #ffffff04;

    .bannerImageMob {
        display: block;
        width: 15%;
        margin: 0 auto;
        border: none;
    }

    .bannerImage {
        
        width: 70%;
        max-width: 100%;
        display: none;
        margin: 0 auto;
    }

    .title {
        width: 100%;
        font-size: 2em; 
        // font-weight: bold;
        font-variant: small-caps;
        text-transform: lowercase;
        text-align: center;
    }

    .content {
        margin-top: 10px;
    }

    // display: inline-block;
}


#sidebar {
    display: inline-block;
    margin: 0 auto;
    width: 80px;
    border: 1px solid white;
}

//

.socialBar {
    display: none;
    position: fixed;
    left: unset;
    top: 0;
    right: 0;
    background-color: transparent;
    position: fixed;
    bottom: unset;
    top: 0;
    
    border-top: none;
    border-left: none;
    left: unset;
    transform: unset;
    
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: unset;
    
    border: 2px solid #ffffff15;
    border-top: none;
    border-right: none;
    border-bottom-left-radius: 20px;
    overflow: hidden;
    transition: transform 500ms;
    &.inactive {
        transform: translateY(-120%);
    }
    .item {
        font-weight: 700;
        padding: 20px;

        text-align: center;

        svg.notDull {
            box-shadow: 0 0 0 120vw white;

        }
        overflow: hidden;
    }
    .item:not(.active) {
    
        font-weight: 700;
        cursor: pointer;
    }

    .item:last-child {
        border-top: unset;
        border-left: unset;
    }

    ul {
        margin: 0;
        // padding: 0;
        font-weight: normal;
        li {
            text-align: left;
        }
    }
}

//

.footer.mobile {


    position: fixed;
    bottom: 0;
    display: flex;
    width: 600px;
    max-width: 100%;
    left: 50%;
    transform: translate(-50%);
    // background-color: rgba(255, 255, 255, 0.9);
    
    overflow-x: auto;
    box-sizing: border-box;

    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    .item {
        flex: 1 0 0; 
        padding: 2px;
        text-align: center;
        // font-variant: small-caps;
        // text-transform: lowercase;
        vertical-align: text-top;
        // font-size: 1vw;
        font-weight: 600;
        cursor: pointer;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.4);

        svg.notDull {
            box-shadow: 0 0 0 80vw white;
            
    
        }
        
    }

    .item.active {
        cursor: initial;
        font-weight: 700;
        
        background: rgba(255, 255, 255, 0.1);
        
    }
}

.footer.desktop {
    
    
    background-color: transparent;
    position: fixed;
    bottom: unset;
    top: 0;
    display: flex;
    
    border-top: none;
    border-left: none;
    left: unset;
    transform: unset;
    
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: unset;
    
    border: 2px solid #ffffff15;
    border-top: none;
    border-left: none;
    border-bottom-right-radius: 20px;
    overflow: hidden;
    .item {
        font-weight: 700;
        padding: 8px;
        
        text-align: center;

        svg.notDull {
            box-shadow: 0 0 0 80vw white;
            
        }
        overflow: hidden;
    }
    .item:not(.active) {
    
        font-weight: 700;
        cursor: pointer;
    }
   
    .item:last-child {
        border-top: unset;
        border-left: unset;
    }
}

.noListStyle {
    padding: 0;
    margin: 0;
    list-style: none;
}


.noListBullet {
    padding-top: 0;
    margin: 0;
    list-style: none;
}

.noListPadding {
    padding-top: 0;
    margin: 0;
}

.fancyColour {
    background-color: black;
            
    /* Create the gradient. */
    background: linear-gradient(2deg,#0e0021,#1e0047,#b71f1f);
    background-size: 400% 400%;
    background-repeat: no-repeat;
    background-attachment: local;

    -webkit-animation: BackgroundAnim 30s ease infinite;
    -moz-animation: BackgroundAnim 30s ease infinite;
    animation: BackgroundAnim 30s ease infinite;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    background-clip: text; // Unsure if this is even real
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

svg.newButton {
    font-family: $generalFonts;
    outline-offset: -0.5px;
    text {
      font-weight: bold;
    }
    rect {
        fill: white;
        // mask: url(#mask);
    }
    &.notDull {
        outline: 1px solid white;
        
    }
    &.notDull.fakeBackground {
        box-shadow: 0 0 0 120vw white;
    }
}

.footer.mobile {
    display: flex;
}
.footer.desktop {
    display: none;
}

@media only screen and (min-width: 800px) {
    .socialBar {
        display: flex;
    }
    .footer.mobile {
        display: none;
    }
    .footer.desktop {
        display: flex;
    }
    
}


#main {
    padding-bottom: 100px;
}


@media only screen and (max-width: 700px) {
    
    #loading.notLoaded {
        .images {
            width: 40vw;
            height: 40vw;
        }
    }
}

#godIHateSafari {

    display: block;
    z-index: -1;
    touch-action: none;
    background: linear-gradient(2deg,#0e0021,#1e0047,#b71f1f);
    background-size: 400% 400%;
    -webkit-animation: BackgroundAnim 30s ease infinite;
    -moz-animation: BackgroundAnim 30s ease infinite;
    animation: BackgroundAnim 30s ease infinite;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
}

@media only screen and (max-width: 600px) {
    #main {
        background-color: unset;
        border: unset;
        .bannerImageMob {
            width: 20%;
        }
    }
    
}

@media only screen and (max-width: 400px) {
    #main {
        
        // .bannerImage {
        //     display: none;
        // }
        .title {
            font-size: 1.5em; 
        }
    }
}

.hidden {
    display: none;
}

.fakeLink {
    cursor: pointer;
    text-decoration: underline;
}

.noOpacity {
    opacity: 0;
    touch-action: none;
    cursor: unset;
}

.ninety {
    width: 90%;
    margin: 0 auto;
}

.editMembers {
    .name {
        text-align: center;
        font-variant: small-caps;
        a {
            text-decoration: none;
            font-size: 1.3em;
        }
    }
    .onlineStamp {
        text-align: center;
        font-size: 0.9em;
    }
    .timestamp {
        text-align: center;
        font-size: 0.9em;
        margin-bottom: 6px;
    }
    
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid white;
    border-radius: 20px;
    margin: 0 auto;
    width: 400px;
    max-width: 98%;
    overflow: hidden;
    margin-bottom: 10px;

    .item.name {
        margin-right: 20px;
        margin-left: 20px;
    }
    .item.button {
        overflow: hidden;
        &.inactive {
            button {
                border: unset;
                svg text {
                    
                    text-decoration: line-through;
                }
            }
        }

        button {
            border-radius: unset;
            height: 100%;
            width: 100%;
        }
        flex: 1;
    }
}

button.fancyButton {
    font-family: $generalFonts;
    
    border: 2px solid white;
    box-sizing: border-box;
    padding: 3px;
    border-radius: 10px;
    font-weight: bold;
    background: transparent;
    span {
        display: block;
    }
    overflow: hidden;
    .newButton.notDull {
        box-shadow: 0 0 0 120vw white;
        outline: 120vw solid white;
    }
    .notFancyColour {
        position: absolute;
        left: -300vw;
        top: -400vh;
        color: white;
    }
    cursor: pointer;
    &:active {
        .newButton text {
            font-weight: bolder;
        }
    }
    &:disabled {
        background: transparent;
        .newButton {
            text {
                fill: rgba(255, 255, 255, 0.8);
            }
        }
        
        font-weight: normal;
        cursor: not-allowed;
        
    }
}


// fancyButton over

.slickContainer {
    .parent {
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
        margin-top: 10px;
        
    }
    .central {
        display: flex;
        
        .object {
            box-sizing: border-box;
            width: 100%;
            flex: 0 0 100%;
            opacity: 0.1;
                
        }
        .object.active {
            opacity: 1;
        }
    }
}

// tooltip over

.tooltip {
    position: relative;
    display: block;
    text-align: center;

    .tooltiptext {
        visibility: hidden;
        max-width: 120px;
        background-color: transparent;
        color:  rgba(0, 0, 0, 1);
        overflow: hidden;
        text-align: center;
        border-radius: 6px;
        font-weight: bolder;
        padding: 5px;
        
        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        bottom: 100%;
        left: 50%;
        margin-left: -60px;
    }

    &:hover {
        .tooltiptext {
            visibility: visible;
        }
    }
}


// slickContainer over

.miniSelect {
    .item {
        
        margin-right: 5px;
        height: 40px;
        width: 40px;
        display: inline-block;
        .circle {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            border: 3px solid white;
            box-sizing: border-box;
            transition: opacity 1s;
            opacity: 0.6;
            text-align: center;
            .text {
                line-height: 2;
                align-self: center;
                font-weight: bold;
            }
        }
        
        vertical-align: top;
        font-variant: small-caps;
        // font-weight: bold;
        cursor: pointer;
        outline: none;
        box-shadow: none;

    }
    .item.active {
        .circle {
            background: white;
            opacity: 0.5;
            // border: none;
        }
    }
    .item.activeLast {
        .circle {
            // background: #ffffff95;
            opacity: 0.9;
        }
    }
    .item.activeNext {
        .circle {
            // background: #ffffff95;
            // opacity: 0.9;
            animation: flickerAnimation 2s infinite;
        }
    }

    // .arrow {
        
    //     display: inline-block;
    //     // overflow: hidden;
    //     div {
    //         width: 0; 
    //         height: 0; 
    //         border-top: 20px solid transparent;
    //         border-bottom: 20px solid transparent;
            
    //         border-left: 30px solid #ffffff95;
    //     }
    // }
    // .item:last-child {
    //     margin: unset;
    // }
}

.specialBar {
    width: 598px;
    max-width: 100%;
    margin-left: -27px;
    top: 0;
    position: fixed;
    text-align: left;
    z-index: 5;
    button {
        min-width: 25%;
        word-wrap: break-word;
        transform: translateY(-120%);
        will-change: transform;
        transition: transform 500ms;
        padding: 8px;
        position: absolute;
        box-shadow: inset 0 0 0 1px white;

        .newButton {
            
            margin-bottom: 1px;
        }
    }
    button:first-of-type, .first {
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        border-bottom-left-radius: unset;
        border-bottom-right-radius: 20px;
    }
    button:last-of-type:not(.only), .last {
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: unset;
        right: -2px;
    }
    button.active {
        transform: none
    }
    transition: top 800ms, opacity 300ms;
    opacity: 1;
}

.specialBar.inactive {
    top: -100%;
    opacity: 0;
}

.division {
    border-top: 1.5px solid white;
    width: 80%;
    margin: 0 auto;
    padding: 1.5px;
    margin-bottom: 10px;

}

.switch {
    margin: 0 auto;
    width: 100%;
    display: flex;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;

    border: 1px solid white;
    border-radius: 20px;
    overflow: hidden;

    .item {
        flex: 1 0; 
        padding: 2px;
        padding-left: 4px;

        padding-right: 4px;
        span {
            margin-left: 10px;
        }
        overflow: hidden;
    }

    .item:not(.active) {
        cursor: pointer;
    }

    .item.active {
        font-weight: bold;
    }

    .item:first-child {
        // text-align: right;
        border-right: 1px solid white;
        
    }
}

.mainPage.inactive {
    // font-family: "Comic Sans MS";
    touch-action: none;
    pointer-events: none;

}

// Page stylings

.content.enter {
    .switch {
        width: 90%;
    }
    .switch.signingUp {
        
        .item {
            cursor: not-allowed;
            font-weight: bold;
            font-variant: small-caps;
        }
    }

    button {
        width: 100%;
        border-width: 1px;
    }

    .parent {
        
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
        margin-top: 10px;
    }

    .central {
        display: flex;
        transition: transform 600ms;
        
        .object {
            
            box-sizing: border-box;
            padding: 10px;
            width: 100%;
            flex: 0 0 100%;
            transition: opacity 700ms;
            opacity: 0;
            .errorBox {
                margin-top: 10px;
                opacity: 0;
                transition: opacity 600ms;
                min-height: 200px;
            }
            .errorBox.active {
                opacity: 1;
            }
            ul.userInfo {
                margin-top: 10px;
                li.listHead {
                    padding-left: 5px;
                    margin-top: 10px;
                    margin-bottom: 5px;
                }
                li.listHead:first-child {
                    margin-top: unset;
                }
            }
        }

        .object.zone3 {
            button {
                width: 100%;
            }
            .continue {
                margin: 0 auto;
                width: 100%;
                display: flex;
                -webkit-box-orient: horizontal;
                -moz-box-orient: horizontal;
                -webkit-flex-direction: row;
                -ms-flex-direction: row;
                flex-direction: row;
                gap: 20px;
                div {
                    flex: 1 0;
                }
            }
            .terms {
                border: 1px solid white;
                border-radius: 20px;
                padding: 20px;

                display: block;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
            }
        }
        
        .object.active {
            opacity: 1;
        }
    }

    .central.mode1 {
        transform: translate(-100%);
    }

    .central.mode2 {
        transform: translate(-200%);
    }

    .central.mode3 {
        transform: translate(0);
    }
}

.content.welcome {
    .introText {
        text-align: center;
        font-variant: small-caps;
        width: 100%;
        // font-size: 2rem;
        font-size: 1.25em;
    }
    .mScontainer {
        display: flex;
        align-items: center;
    }
    img {
        margin-bottom: 10px;
    }
}

.content.profile {
    
    .username {
        padding: 0;
        line-height: 0.8;
        text-align: center;
        font-size: 2em;
        font-variant: small-caps;
        margin-bottom: 10px;
        
    }
    .bio {
        text-align: center;
        font-size: 1em;
        margin-bottom: 5px;
    }
    .bio.editing {
        margin-bottom: unset;
        textarea {
            font-weight: bold;
            width: 90%;
            border: 1px solid #ffffff30;
            padding: 10px;
            border-radius: 25px;
            line-height: 1.2;
            box-sizing: content-box;
        }
    }
    .timestamp {
        
        text-align: center;
        font-size: 0.9em;
        // font-variant: small-caps;
    }
    .profilePic {
        max-width: 90%;
        display: block;
        margin: 0 auto;
        margin-top: 10px;
    }
    .profilePicEditing {
        max-width: 100%;
        display: block;
        margin: 0 auto;
        margin-top: 10px;
        box-sizing: border-box;
    }
    .profilePicEditing.editing {
        filter: sepia(100%);
        max-width: 100%;
    }
    .profilePicParent {
        position: relative;
        width: 90%;
        margin: 0 auto;
        .specialText {
            border: 1px solid #ffffff35;
            border-top-color: transparent;
            border-left-color: transparent;
            // box-sizing: border-box;
            position: absolute;
            top: 2px;
            margin-left: 2px;
            padding: 25px;
            border-top-left-radius: 25px;
            border-bottom-right-radius: 25px;
            background: transparent;
            cursor: pointer;
            overflow: hidden;
        }
        .specialText.partHidden {
            background: #00000020;
            border-color: transparent;
            font-style: italic;
        }
    }
    .groupMenu, .removeFriend {
        width: 90%;
        margin: 0 auto;
        margin-top: 10px;
    }
    .removeFriend {
        font-size: 0.9em;
        text-align: right;
        .bigger {
            font-size: 1em;
            font-weight: bold;
        }
    }
    p {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .infoList {
        input {
            margin-right: 10px;
        }
    }

    .thisIsMe {
        font-weight: bold;
    }
    
    

    .info {
        margin-bottom: 10px;
        width: 90%;
        margin: 0 auto;
    }
    .info:last-of-type {
        margin-bottom: 0;
    }
}

.content.viewDaySingle {
    .dayInfo, .weekInfo, .daySelect {
        font-style: italic;
        margin-bottom: 10px;
        
    }
    .dayButtons {
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;
        margin-bottom: 10px;
        margin-top: 5px;
        .fancyButton {
            min-width: 20%;
        }
        .fakeLink {
            font-size: 14pt;
            font-weight: bold;
            display: flex;
            align-items: center;
        }
    }
    .dayStart {
        width: 100%;
        margin-bottom: 10px;
        
        text-transform: uppercase;
        // font-variant: small-caps;
    }
    .bottom {
        .dayStart {
            margin-top: 10px;
            margin-bottom: 5px;
        }
        .regular {
            margin-bottom: unset;
        }
    }
    .important {
        font-size: 12pt;
        // border-radius: 50%;
        // width: 34px;
        // height: 34px;
        // padding: 10px;
        // background: #fff;
        // border: 3px solid #000;
        // color: #000;
        // text-align: center;
        // font: 32px Arial, sans-serif;
    }
}

.viewDayOptions {
    .textContent {
        margin-top: 5px;
    }
    .new {
        margin-top: 10px;
        margin-bottom: 5px;
    }
    .friendSelect {
        margin-bottom: 5px;
    }
    .startButton {
        margin-top: 10px;
        width: 100%;
    }
    .sepButtons {
        margin-top: 5px;
    }
    .groupInfo {
        margin-top: 10px;
        border: 1px solid white;
        border-radius: 15px;
        padding: 15px;
        .groupName {
            font-weight: bold;
        }
    }
}

// dev-css override

.dev {
    &, #main, .slickContainer .parent {
        overflow: visible !important;
    }
}